/*-----------------------------------------------
|   Data table
-----------------------------------------------*/
.dataTables_wrapper .dataTables_info{
  padding-top: map_get($spacers, 3) !important;
  font-size: map_get($font-sizes, '-1');
}
.data-table{
  td, th{
    white-space: nowrap;
  }
}
.dataTables_filter input{
  max-width: 15rem;
}

.xzoom { box-shadow: none; }
.xzoom-preview { 
  box-shadow: none;
  border: 1px solid $gray-300;
  border-radius: $border-radius;
  margin-left: 10px;
  z-index: 2;
}
.xzoom-gallery {
  margin-left: 0;
}
.xzoom-lens { 
  box-shadow: $box-shadow; 
  border: 1px solid $gray-700; 
  border-radius: $border-radius-soft; 
}
/*-----------------------------------------------
|   Email
-----------------------------------------------*/
.email-actions {
  display: none;
  position: absolute;
  z-index: 1;
}
.email-actions-trigger{
  position: relative;
  a {
    color: inherit;
    text-decoration: none;
  }
  @include hover-focus {
    box-shadow: 0 0 0.5rem 0 rgba($black, 0.1);
    z-index: 1;
    .email-actions {
      display: flex;
    }
  }
}

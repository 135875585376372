/*-----------------------------------------------
|   Dropdowns
-----------------------------------------------*/
.dropdown-menu{
  box-shadow: $box-shadow;
}
.dropdown-item{
  transition: $transition-base;
  padding-top: map_get($spacers, 1);
  padding-bottom: map_get($spacers, 1);
  @include hover{
    background-color: $gray-200 !important;
  }
}
.dropdown-caret-none::after{
  display: none;
}
